import Cryptr from "cryptr";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { useState } from "react";

/*** COLOR FUNCTIONS ***/
export function numberToColour(number) {
  const b = (number & 0xff0000) >> 16;
  const g = (number & 0x00ff00) >> 8;
  const r = number & 0x0000ff;
  return [r, g, b];
  // or eg. return `rgb(${r},${g},${b})`;
}
export function getHexColor(number) {
  return "#" + (number >>> 0).toString(16).slice(-6);
}
export function getTextColor(intColor) {
  let rgb = numberToColour(intColor);
  var o = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[1]) * 114) /
      1000
  );
  var fore = o > 125 ? "black" : "white";
  return fore;
}

/*** STRING FUNCTIONS ****/

export function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

/**** APP FUNCTIONS ****/

export function convertVersionToPort(version) {
  let v = replaceAll(version, ".", "");
  return v;
}
export function getUrlApi() {
  if (process.env.REACT_APP_API_PORT) {
    return process.env.REACT_APP_API_URL + ":" + process.env.REACT_APP_API_PORT;
  } else {
    return (
      process.env.REACT_APP_API_URL +
      ":" +
      (20000 + parseInt(convertVersionToPort(process.env.REACT_APP_API_VS)))
    );
  }
}
export function getUrlSite() {
  return process.env.REACT_APP_URL + ":" + process.env.REACT_APP_PORT;
}
export function getUrlSiteNoPort() {
  return process.env.REACT_APP_URL;
}
export function getDBCONF() {
  let DBConf = {
    DB_HOST: process.env.REACT_APP_DB_HOST,
    DB_USER: process.env.REACT_APP_DB_USER,
    DB_PASSWORD: process.env.REACT_APP_DB_PASSWORD,
    DB_NAME: process.env.REACT_APP_DB_NAME,
    DB_PORT: process.env.REACT_APP_DB_PORT,
  };
  return encrypt(JSON.stringify(DBConf), process.env.REACT_APP_ENCRYPTDBINFO);
  //return JSON.stringify(DBConf);
}

export function getMENUCONF(MENUConf) {
  if (MENUConf !== undefined && MENUConf !== null && MENUConf !== "") {
    try {
      let MENUConfStr = decrypt(MENUConf, process.env.REACT_APP_ENCRYPTDBINFO);
      //let MENUConfStr=MENUConf;
      return MENUConfStr;
    } catch (er) {
      return "";
    }
  } else {
    return "";
  }
}
export function setMENUCONF(MENUConf) {
  let MENUConfStr = encrypt(MENUConf, process.env.REACT_APP_ENCRYPTDBINFO);
  //let MENUConfStr=MENUConf;
  return MENUConfStr;
}

export function encrypt(text, key) {
  let cryptr = new Cryptr(key);
  let encryptedString = cryptr.encrypt(text);
  //var crypt = new Crypt();
  //let encryptedString =crypt.encrypt(key,text);
  return encryptedString;
}

export function decrypt(encryptedText, key) {
  let cryptr = new Cryptr(key);
  let encryptedString = cryptr.decrypt(encryptedText);
  //var crypt = new Crypt();
  //let encryptedString =crypt.decrypt(key,encryptedText);
  //return encryptedString.message;
  return encryptedString;
}
export function findObjectByKey(array, key, value) {
  for (var i = 0; i < array.length; i++) {
    if (array[i][key] == value) {
      return array[i];
    }
  }
  return null;
}

export function getMailtoUrl(to, subject, body) {
  var args = [];
  if (typeof subject !== "undefined") {
    args.push("subject=" + encodeURIComponent(subject));
  }
  if (typeof body !== "undefined") {
    args.push("body=" + body);
  }

  var url = "mailto:" + encodeURIComponent(to);
  if (args.length > 0) {
    url += "?" + args.join("&");
  }
  return url;
}

export function inArray(comparer, array) {
  for (var i = 0; i < array.length; i++) {
    if (comparer(array[i])) return true;
  }
  return false;
}

// adds an element to the array if it does not already exist using a comparer
// function
export function pushIfNotExist(element, comparer, array) {
  if (!inArray(comparer, array)) {
    array.push(element);
  }
}
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function removeDuplicates(arr, prop) {
  if (arr.length == 0) {
    return arr;
  }
  let obj = {};
  return Object.keys(
    arr.reduce((prev, next) => {
      if (!obj[next[prop]]) obj[next[prop]] = next;
      return obj;
    }, obj)
  ).map((i) => obj[i]);
}

export function getTextFromJSONLink(link) {
  if ((link !== null) & (link !== undefined)) {
    try {
      let JSONlink = JSON.parse(link);
      if (JSONlink.valore) {
        return JSONlink.valore;
      }
      if (JSONlink.value) {
        return JSONlink.value;
      }
    } catch (ex) {
      return undefined;
    }
  } else {
    return undefined;
  }
}
export function getDataTypeFromJSONLink(link) {
  if ((link !== null) & (link !== undefined)) {
    try {
      let JSONlink = JSON.parse(link);
      return JSONlink.dataType;
    } catch (ex) {
      return undefined;
    }
  } else {
    return undefined;
  }
}
export function getOrdinamentoFromJSON(jsonObj) {
  if ((jsonObj !== null) & (jsonObj !== undefined)) {
    try {
      let obj = JSON.parse(jsonObj);
      return obj.ordinamento;
    } catch (ex) {
      return undefined;
    }
  } else {
    return undefined;
  }
}
export function bufferToBase64(buf) {
  var binstr = Array.prototype.map
    .call(buf, function (ch) {
      return String.fromCharCode(ch);
    })
    .join("");
  return btoa(binstr);
}
export function base64ArrayBuffer(arrayBuffer) {
  var base64 = "";
  var encodings =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
}
export function decodeArrayBuffer(buffer) {
  var mime;
  var a = new Uint8Array(buffer);
  var nb = a.length;
  if (nb < 4) return null;
  var b0 = a[0];
  var b1 = a[1];
  var b2 = a[2];
  var b3 = a[3];
  if (b0 == 0x89 && b1 == 0x50 && b2 == 0x4e && b3 == 0x47) mime = "image/png";
  else if (b0 == 0xff && b1 == 0xd8) mime = "image/jpeg";
  else if (b0 == 0x47 && b1 == 0x49 && b2 == 0x46) mime = "image/gif";
  else return null;
  var binary = "";
  for (var i = 0; i < nb; i++) binary += String.fromCharCode(a[i]);
  var base64 = window.btoa(binary);
  return "data:" + mime + ";base64," + base64;
}
export function getPrimaryColor() {
  return process.env.REACT_APP_CONF_FIRSTCOLOR
    ? process.env.REACT_APP_CONF_FIRSTCOLOR
    : "#00A09B";
}
export function eItalic(x) {
  if (x !== undefined && x !== null) {
    return x.toLocaleString("it-IT", { style: "currency", currency: "EUR" });
  } else {
    return x;
  }
}
export function MergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = MergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

export function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
export function formatTimeDuration(duration) {
  var seconds = Math.abs(Math.ceil(duration)),
    h = (seconds - (seconds % 3600)) / 3600,
    m = ((seconds - (seconds % 60)) / 60) % 60,
    s = seconds % 60;
  return (
    (duration < 0 ? "-" : "") +
    h +
    ":" +
    m.toString().padStart(2, "0") +
    ":" +
    s.toString().padStart(2, "0")
  );
}

export async function callStructure(entita, cookie, location, fromTable) {
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  //let userCookie = cookie.get("JEProjectUSER");
  //let cc = encrypt(userCookie, process.env.REACT_APP_ENCRYPTDBINFO);

  let body = {
    getStructure: true,
    getData: false,
    columnsSelection: "*",
    entita: entita,
    filter: [{}],
    token: token,
    uuid: uuid,
    conf: getDBCONF(),
    fromTable: fromTable == undefined ? true : fromTable,
    // userCookie: userCookie,
    location: location,
  };
  if (entita !== undefined) {
    let response = await fetch(getUrlApi() + "/api/getDataNew", {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then((results) => results.json())
      .then(function (json) {
        if (json.error) {
          if (json.UUIDLogout) {
            logoutHelper();
          }

          return null;
        } else {
          return json;
        }
      })
      .catch(function (error) {
        return {
          error: true,
          error,
        };
      });

    return response;
  } else {
    return null;
  }
}

export async function getUserInfo(cookie, idJeSettaggi, proprieta) {
  let sezione = idJeSettaggi;
  let oggetto = idJeSettaggi;
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  let body = {
    sezione: sezione,
    oggetto: oggetto,
    proprieta: proprieta,
    token: token,
    uuid: uuid,
    conf: getDBCONF(),
  };

  let response = await fetch(getUrlApi() + "/api/userConfiguration/get", {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then(function (json) {
      if (json && json.error) {
        if (json.UUIDLogout) {
          logoutHelper();
        }
        return null;
      }
      return json;
    })
    .catch(function (error) {
      return error;
    });
  return response;
}
export async function updateUserInfo(cookie, idJeSettaggi, proprieta, valore) {
  let sezione = idJeSettaggi;
  let oggetto = idJeSettaggi;
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  let body = {
    sezione: sezione,
    oggetto: oggetto,
    proprieta: proprieta,
    valore: valore,
    token: token,
    uuid: uuid,
    conf: getDBCONF(),
  };

  let response = await fetch(getUrlApi() + "/api/userConfiguration/update", {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then(function (json) {
      if (json.error) {
        if (json.UUIDLogout) {
          logoutHelper();
        }
        return null;
      }
      return json;
    })
    .catch(function (error) {});

  return response;
}
export async function callStoreProcedure(
  filtri,
  cookie,
  location,
  storeProcedureName
) {
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  let userCookie = encrypt(
    JSON.stringify(cookie.get("JEProjectUSER")),
    process.env.REACT_APP_ENCRYPTDBINFO
  );
  let filtriIn = [];
  let filtriOut = [];
  filtri.map(function (obj) {
    if (obj.output) {
      filtriOut.push(obj);
    } else {
      filtriIn.push(obj);
    }
  });
  let body = {
    conf: getDBCONF(),
    token: token,
    uuid: uuid,
    storeName: setMENUCONF(storeProcedureName),
    parametri: setMENUCONF(JSON.stringify(filtriIn)),
    parametriout: setMENUCONF(JSON.stringify(filtriOut)),
    userCookie: userCookie,
    location: location,
  };

  let response = await fetch(getUrlApi() + "/api/callScript", {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then(function (result) {
      if (result.error) {
        if (result.UUIDLogout) {
          logoutHelper();
          return null;
        }
      }
      let r = result;
      return r;
    })
    .catch(function (error) {
      return error;
      //caso di errore
    });
  return response;
}
export function parseStringForDefaultFilter(strDefault) {
  var def = {};

  let parametri = strDefault.split(",");
  for (var index in parametri) {
    let f = parametri[index].split("=");
    def[f[0]] = f[1];
  }
  return def;
}
export function getDefault(strDefault) {
  var def = {};
  try {
    def = JSON.parse(strDefault);
    return def.default;
  } catch (err) {}
  return parseStringForDefaultFilter(strDefault);
}
export function getFilter(strDefault) {
  var def = {};
  try {
    def = JSON.parse(strDefault);
    return def.filter;
  } catch (err) {}

  return parseStringForDefaultFilter(strDefault);
}

export function updateToken(
  cookieName,
  durata,
  cookieTimestampName,
  cookieValue,
  history,
  redirectPath,
  encodedRouteParameters,
  callbackError
) {
  let cookies = new Cookies();
  let currentDate = new Date();
  let currentToken = cookieValue;
  let token = cookies.get(cookieName);
  let strLogin = setMENUCONF(JSON.stringify(currentToken));
  let apiBody;
  let apiUrl;

  if (token !== undefined) {
    apiBody = { strLogin: strLogin, durationTokenDays: durata };
    apiUrl = "/api/updateToken";
  } else {
    apiBody = { strLogin: strLogin, durationTokenDays: durata };
    apiUrl = "/api/login";
  }
  const onSuccess = (json) => {
    if (json.error) {
      if (history != null) {
        history.replace("/login");
      }
      if (callbackError) {
        callbackError(json.message);
      }
    } else {
      cookies.set(cookieName, json.token, {
        path: "/",
        maxAge: 60 * 60 * 24 * durata,
      });
      cookies.set(cookieTimestampName, currentDate, {
        path: "/",
        maxAge: 60 * 60 * 24 * durata,
      });
      cookies.set("JEProjectUUID", b64EncodeUnicode(json.uuid), {
        path: "/",
        maxAge: 60 * 60 * 24 * durata,
      });

      if (history != null && redirectPath != null && redirectPath != "") {
        if (encodedRouteParameters != null) {
          history.replace(`/${redirectPath}/:${encodedRouteParameters}`);
        } else {
          if (redirectPath != "/" && redirectPath.length > 0) {
            history.replace(`/${redirectPath}`);
          } else {
            history.replace(`/`);
          }
        }
      } else {
        if (history != null) {
          history.replace(`/`);
        }
      }
    }
  };

  const onError = (error) => {
    if (callbackError) {
      callbackError(error);
    }
    if (history != null) {
      history.replace("/login");
    }
  };

  callApi(apiUrl, "POST", apiBody, token, onSuccess, onError);
}

export async function callApi(
  name,
  method,
  body,
  apiToken,
  successCallback,
  errorCallback
) {
  let apiBody = {
    conf: getDBCONF(),
    ...body,
  };

  if (apiToken) {
    apiBody.token = apiToken;
  }

  await fetch(`${getUrlApi()}${name}`, {
    method,
    body: JSON.stringify(apiBody),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then((json) => successCallback(json))
    .catch(errorCallback);
}

export async function callData(
  cookie,
  entita,
  apiName,
  filtroData,
  columnsSelection,
  orderByString,
  parametersOrderBy,
  fromTable,
  getStructure,
  getData
) {
  // if(self.state.chiamatoData==0){
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  let body = {
    getStructure: getStructure,
    getData: getData,
    columnsSelection: columnsSelection,
    entita: entita,
    filtro: filtroData,
    token: token,
    uuid: uuid,
    conf: getDBCONF(),
    fromTable: fromTable,
    orderByString: orderByString,
    parametersOrderBy: parametersOrderBy,
  };
  //  if(entita!==undefined){
  let response = await fetch(getUrlApi() + apiName, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then(function (json) {
      if (json.error) {
        if (json.UUIDLogout) {
          logoutHelper();
        }

        return null;
      }
      return json;
    })
    .catch(function (error) {
      return error;
    });

  return response;
}

export async function callDataNew(
  cookie,
  entita,
  apiName,
  filtroData,
  parametroData,
  columnsSelection,
  orderByString,
  parametersOrderBy,
  fromTable,
  getStructure,
  getData
) {
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  let body = {
    getStructure: getStructure,
    getData: getData,
    columnsSelection: columnsSelection,
    entita: entita,
    filtro: filtroData,
    parametro: parametroData,
    token: token,
    uuid: uuid,
    conf: getDBCONF(),
    fromTable: fromTable,
    orderByString: orderByString,
    parametersOrderBy: parametersOrderBy,
  };

  let response = await fetch(getUrlApi() + apiName, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then(function (json) {
      if (json.error) {
        if (json.UUIDLogout) {
          logoutHelper();
        }
        return null;
      }
      return json;
    })
    .catch(function (error) {
      return error;
    });

  return response;
}

export async function callFunctionTable(cookie, entita, filters, fromTable) {
  let token = cookie.get("JEProjectJWTTK");
  let uuid = b64DecodeUnicode(cookie.get("JEProjectUUID"));
  let body = {
    conf: getDBCONF(),
    token: token,
    uuid: uuid,
    entita,
    filtro: filters,
    fromTable: fromTable,
  };
  let response = await fetch(getUrlApi() + "/api/executeFunctionTable", {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then((results) => results.json())
    .then(function (json) {
      if (json.error) {
        if (json.UUIDLogout) {
          logoutHelper();
        }

        return null;
      }
      return json;
    })
    .catch(function (error) {});

  return response;
}

export function dec2bin(dec) {
  return (dec >>> 0).toString(2);
}
export function string2Bin(str) {
  var result = [];
  for (var i = 0; i < str.length; i++) {
    result.push(str.charAt(i));
  }
  if (result.length < 8) {
    let j = 8 - result.length;
    for (var i = 0; i < j; i++) {
      result.unshift("0");
    }
  }
  return result;
}
//CHANGED "index <= 2" -> "index <= 1"
export function getDelayValue(array) {
  let strVal = "";
  let carray = [...array];
  carray.map((ch, index) => {
    if (index <= 1) {
      strVal += ch;
    }
  });
  return parseInt(strVal, 2);
}
export function getColorValue(array) {
  let strVal = "";
  let carray = [...array];
  carray.map((ch, index) => {
    if (index > 2 && index <= 5) {
      strVal += ch;
    }
  });
  let intColor = parseInt(strVal, 2);
  let descColor;
  if (intColor == 6) {
    descColor = "primary";
  }
  if (intColor == 7) {
    descColor = "secondary";
  }
  if (intColor == 1) {
    descColor = "success";
  }
  if (intColor == 5) {
    descColor = "danger";
  }
  if (intColor == 4) {
    descColor = "warning";
  }
  if (intColor == 0) {
    descColor = "light";
  }
  if (intColor == 2) {
    descColor = "info";
  }
  if (intColor == 3) {
    descColor = "dark";
  }
  return descColor;
}
export function decodeDataInfoToToaster(datainfo) {
  let multipleSec =
    process.env.REACT_APP_SECONDS_MULTIPLE > 0
      ? process.env.REACT_APP_SECONDS_MULTIPLE
      : 5;
  let strbin = dec2bin(datainfo.state);
  let stateBinary = string2Bin(strbin);
  let message = datainfo.message;
  let delay = getDelayValue(stateBinary) * multipleSec * 1000; //Time - changed
  let visibile = stateBinary[stateBinary.length - 1] == 1 ? true : false;
  let suonoripetitivo = stateBinary[stateBinary.length - 2] == 1 ? true : false;
  let aggiornamento = stateBinary[stateBinary.length - 6] == 1 ? true : false; //Bit in quinta posizione
  let toasterInfo = {};
  toasterInfo.autohide = delay == 0 ? false : true;
  toasterInfo.color = getColorValue(stateBinary);
  toasterInfo.message = message;
  toasterInfo.delay = delay;
  toasterInfo.visibile = visibile;
  toasterInfo.suonoripetitivo = suonoripetitivo;
  toasterInfo.aggiornamento = aggiornamento;
  return toasterInfo;
}

export function expandExpression(expression) {
  /*
    Data un'espressione matematica costituita da soli prodotti e somme, restituisce un array di valori, sulla base dell'operazione descritta
      Se + : popola l'array con i valori che formano la somma. Esempio: 2+1 -> [2,1]
      Se * : popola l'array con il valore del primo membro il numero di volte specificato dal secondo. Esempio: 2*3 -> [2,2,2]
    
    RETURN: L'array o null in caso di errore
    ERRORS: Errore se l'espressione contiene operazioni diverse da somma e prodotto o se sono presenti prodotti con più di 2 membri
  */

  if (/(?:(?:^|[+*])(?:\s*-?\d?\s*))+$/.test(expression)) {
    let result = [];
    let error = false;
    let products = expression.split(/[+]/);
    products.map(function (expr) {
      if (isNaN(expr)) {
        let nums = expr.split(/[*]/);
        if (nums.length > 2) error = true;
        else {
          for (let i = 0; i < nums[1]; i++) result.push(nums[0]);
        }
      } else result.push(expr);
    });
    if (error) return null;
    else return result;
  } else return null;
}
export function isIsoDate(str) {
  /* 
    Data una stringa restituisce true se questa rappresenta una data in formato ISO
  */
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}

/**
 * Data una struttura dati nel formato restituito dal componente JEDynamicFilter costruisce un array di oggetti
 * nella forma accettata per il filtraggio dei dati da parte degli altri componenti
 * @param {object} structure Oggetto dove ogni chiave indica il nome di una colonna e il suo valore specifica il tipo di dato della colonna ed i valori selezionati
 * {
 *  COLUMNNAME: {
 *                FILTERS: Array dove ogni elemento corrisponde ad un valore della colonna
 *                DATATYPE: Tipo di dato per la colonna
 *                DATAMAXLENGTH: Lunghezza massima per i dati
 *              }
 * }
 */
export function generateFiltersFromDynamicStructure(structure) {
  let filters = [];
  let columns = Object.keys(structure);

  columns.map((column) => {
    // Solo se è definito l'array di valori costruisce i filtri, se non è definito non filtra quella colonna estraendone quindi tutti i valori
    if (structure[column].FILTERS) {
      let values = structure[column].FILTERS;
      if (values.length > 0) {
        values.map((value, index) => {
          let filter = {};
          //Tutti i filtri vengono messi in OR, tranne l'ultimo che sarà in END
          if (index == 0) {
            filter = {
              colonna: column,
              operatore: "=",
              sqloperatore: "AND",
              tipo: structure[column].DATATYPE,
              valore: value,
              var: column + index,
            };
            filters.push(filter);
          } else {
            filter = {
              colonna: column,
              operatore: "=",
              sqloperatore: "OR",
              tipo: structure[column].DATATYPE,
              valore: value,
              var: column + index,
            };
            filters.push(filter);
          }
        });
      } else {
        // Se l'array dei filtri è vuoto
        // Da gestire in sviluppi futuri
      }
    }
  });

  return filters;
}

/**
 * Funzione che prende una stringa in input e la codifica in base64
 * Permette di risolvere il problema della codfica btoa che non supporta le stringhe JS che utilizzano 16-bit per carattere
 * @param {string} str Stringa da codificare
 * @returns
 */
export function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

/**
 * Funzione che prende una stringa base64 e la decodifica in formato supportato da JS
 * Operazione inversa della funzione b64EncodeUnicode
 * @param {string} str Stringa da decodificare
 * @returns
 */
export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  if (str != undefined) {
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  } else {
    return null;
  }
}

/**
 * Questo metodo viene chiamato quando l'UUID non è congruo con il database, di conseguenza
 * viene fatto eseguire il logout dell'utente e gli vengono eliminati i cookie.
 */
export function logoutHelper() {
  const cookies = new Cookies();
  let cryptr = new Cryptr(process.env.REACT_APP_ENCRYPTDBINFO);
  cookies.remove("JEProjectJWTTK");
  cookies.remove("JEProjectTMP");
  cookies.remove("JEProjectUUID");
  cookies.remove("JEProjectUSER");
  cookies.set("JEProjectUUIDError", cryptr.encrypt("true"));
  window.location.href = getUrlSite();
}

/**
 * Metodo utilizzare per calcolare il tipo di dispositivo in uso
 * per interagire con le proprietà (usato per il responsive)
 */
export function getTypeDevice() {
  let deviceType = "desktop";

  if (
    window.innerWidth <= 767 //576
  ) {
    deviceType = "mobile";
  } else if (window.innerWidth > 767 && window.innerWidth < 990) {
    deviceType = "tablet verticale";
  } else if (window.innerWidth <= 1300) {
    deviceType = "tablet";
  }

  return deviceType;
}

/**
 * Metodo utilizzato per generare un token criptato di dimensioni ridotte rispetto
 * al token originale, utilizzato nei tag.
 */
export function encryptTag(text) {
  // Tronca l'input se necessario per assicurare che l'output non superi 135 byte
  const maxInputLength = 101; // 101 caratteri * 4/3 (rapporto di codifica base64) ≈ 135 byte
  let key = process.env.REACT_APP_ENCRYPTDBINFO;
  if (text.length > maxInputLength) {
    text = text.slice(0, maxInputLength);
  }
  let result = "";
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    const keyChar = key.charCodeAt(i % key.length);
    result += String.fromCharCode((charCode + keyChar) % 256);
  }
  // Codifica in base64 e rimuovi il padding '=' per risparmiare spazio
  return btoa(result).replace(/=+$/, "");
}

export function mergeArrays(arr1, arr2) {
  const mergedArray = [];

  // Unisci i due array di oggetti
  const combined = [...arr1, ...arr2];

  // Usa un set per tenere traccia delle chiavi uniche
  const uniqueKeys = new Set();

  // Aggiungi ogni oggetto, unendo le proprietà uniche
  combined.forEach((obj) => {
    const mergedObj = {};
    Object.keys(obj).forEach((key) => {
      // Se la chiave non è ancora stata aggiunta, la aggiungi all'oggetto risultante
      if (!uniqueKeys.has(key)) {
        mergedObj[key] = obj[key];
        uniqueKeys.add(key);
      }
    });
    mergedArray.push(mergedObj);
  });

  return mergedArray;
}

export function removeDuplicatesByProperty(array, property) {
  const uniqueArray = array.filter(
    (item, index, self) =>
      index === self.findIndex((obj) => obj[property] === item[property])
  );
  return uniqueArray;
}

export function isHTML(str) {
  var a = document.createElement("div");
  a.innerHTML = str;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
}
